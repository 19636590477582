import axios from "axios";

const axiosObject = axios.create({
    baseURL: "",
    headers: {
        "Content-type": "application/json",
        'token': ''
    }
});

export default axiosObject;