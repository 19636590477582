import Vue from 'vue'
import { PiniaVuePlugin, defineStore } from 'pinia'

Vue.use(PiniaVuePlugin);

export const userStore = defineStore('UserStore', {
    state: () => ({
        token: false,
        user: null,
        incomingUrl: "/",
    }),
    // computed properties
    getters: {
        getToken: (state) => state.token,
        getUserState: (state) => state.user,
        getIncomingUrl: (state) => state.incomingUrl,
    },
    // methods
    actions: {
        tokenSet(token) {
            this.token = token;
        },
        tokenUnSet() {
            this.token = false;
        },
        setUserState(state) {
            this.user = state;
        },
        unsetUserState() {
            this.user = null;
        },
        setIncomingUrl(url) {
            this.incomingUrl = url;
        },
        resetIncomingUrl() {
            this.incomingUrl = "/";
        },
    },
    persist: true,
});


