<template>
    <v-scroll-x-reverse-transition mode="out-in">
        <router-view />
    </v-scroll-x-reverse-transition>
</template>

<script>

export default {
    name: 'App',
    metaInfo: {
        title: 'SMTMS | SMT Jetty',
    }
};
</script>
